import '@unifly-aero/proj-common/src/styles/theme.scss';
import { Aurelia } from 'aurelia-framework';
import { bootstrap } from 'aurelia-bootstrapper';
import { configure as configurePortal, start as startPortal } from '@unifly-aero/web-portal/src/main';
import { registerMapLayersFactory, registerInformationMenuProvider } from '@unifly-aero/web-common/src/main';
import AppConfiguration from './config/AppConfiguration';
import {NavcanMapLayers} from "@unifly-aero/proj-common/src/layers/navcan-map-layers";
import { AirportDetailsViewExtension } from "@unifly-aero/web-common/src/unifly-luciad/layers/detail-view/airport-details-view-extension";
import { AirspaceDetailsViewExtension } from "@unifly-aero/web-common/src/unifly-luciad/layers/detail-view/airspace-details-view-extension";
import { UasZoneDetailsViewExtension } from '@unifly-aero/web-common/src/unifly-luciad/layers/detail-view/uas-zone-details-view-extension';
import {NavcanMetaDataDetails} from "@unifly-aero/proj-common/src/unifly-luciad/layers/detail-view/navcan-meta-data-details";
import { InformationMenuProviderNavcan } from '@unifly-aero/proj-common/src/layout/sidebar/information-menu-provider-navcan';
import {ValidationStatusStyleConverter} from "@unifly-aero/web-common/src/operator/uasoperation/converters/ValidationStatusStyleConverter";
import {NavCanValidationStatusStyleConverter} from "@unifly-aero/proj-common/src/uasoperation/uas-operation-details/NavCanValidationStatusStyleConverter";

export function configure(aurelia: Aurelia) {
    configurePortal(aurelia, AppConfiguration);
    startPortal(aurelia);

    registerMapLayersFactory(aurelia.container, NavcanMapLayers);
    registerInformationMenuProvider(aurelia.container, InformationMenuProviderNavcan);

    aurelia.container.registerSingleton(AirportDetailsViewExtension, NavcanMetaDataDetails);
    aurelia.container.registerSingleton(AirspaceDetailsViewExtension, NavcanMetaDataDetails);
    aurelia.container.registerSingleton(UasZoneDetailsViewExtension, NavcanMetaDataDetails);
    aurelia.container.registerSingleton(ValidationStatusStyleConverter,  NavCanValidationStatusStyleConverter);
}

bootstrap(configure);
