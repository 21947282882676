import { AppConfig } from '@unifly-aero/web-common/src/config/AppConfig';
import PortalAppConfiguration from '@unifly-aero/web-portal/src/config/AppConfiguration';
import CommonAppConfiguration from '@unifly-aero/proj-common/src/config/AppConfiguration';
import merge from 'lodash.merge';

const AppConfiguration: AppConfig = merge<AppConfig, DeepPartial<AppConfig>, DeepPartial<AppConfig>>(CommonAppConfiguration, PortalAppConfiguration, {
    clientId: 'NavcanPortal',
    clientSecret: '',
    userConfirmationClientId: 'NavcanUserConfirmation',
    userConfirmationClientSecret: '293857aa-7127-47d1-8d94-cd3e448de8da',
    geoBufferWidthInMetres: 10,
    i18n: {
        options: {
            ns: ['proj-portal', 'proj-common', 'portal', 'common'],
            fallbackNS: ['proj-portal', 'proj-common', 'portal', 'common'],
            defaultNS: 'proj-portal'
        }
    }
});

export default AppConfiguration;
